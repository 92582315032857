import React, {useState} from "react";
import {createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import {FIREBASE_AUTH} from "../firebaseConfig";
import {commonMessageFromFirebaseErrorCode, ErrorModal} from "./ErrorModal";
import {Button, TextField} from "@mui/material";
import {buttonStyle} from "../util/styles";

export const CreateAccount = (props) => {
    const {setIsCreateAccountOpen, setSuccessMessage} = props
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('')
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    return (
        <div style={{display: "flex",  flexDirection: 'column'}}>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#ffffff'}}>Let's get started!</h2>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <TextField
                    InputProps={{
                        style: {
                            color: '#ffffff',
                        },
                    }}
                    style={{width: '75%', border: '2px solid #ffffff', color: '#ffffff'}}
                    onChange={(event) =>  setUsername(event.target.value)}
                    autoCapitalize={'none'}
                    value={username}
                    placeholder={'Enter Email'}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <TextField
                    InputProps={{
                        style: {
                            color: '#ffffff',
                        },
                    }}
                    style={{width: '75%', border: '2px solid #ffffff', color: '#ffffff'}}
                    onChange={(event) => setPassword(event.target.value)}
                    autoCapitalize={'none'}
                    value={password}
                    type={'password'}
                    secureTextEntry={true}
                    placeholder={'Enter Password'}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <TextField
                    error={password !== verifyPassword}
                    type={'password'}
                    InputProps={{
                        style: {
                            color: '#ffffff',
                        },
                    }}
                    style={{width: '75%', border: '2px solid #ffffff', color: '#ffffff'}}
                    onChange={(event) => setVerifyPassword(event.target.value)}
                    autoCapitalize={'none'}
                    value={verifyPassword}
                    secureTextEntry={true}
                    placeholder={'Verify Password'}/>
            </div>
            <Button
                variant={'contained'}
                disabled={verifyPassword === '' || verifyPassword !== password}
                style={buttonStyle}
                onClick={() =>{
                    createUserWithEmailAndPassword(FIREBASE_AUTH, username,
                        password)
                    .then((userCredential) => {
                        const user = userCredential.user;
                        setSuccessMessage(`Verification email sent to ${user.email}.`)
                        sendEmailVerification(user)
                        .then(() => {})
                        .catch((error) => {
                            const errorCode = error.code
                            commonMessageFromFirebaseErrorCode(errorCode, setErrorMessage)
                        })
                        .finally(() => setIsCreateAccountOpen(false));
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        commonMessageFromFirebaseErrorCode(errorCode, setErrorMessage)
                    })
                }}>
                <div >Create Account</div>
            </Button>
            <Button
                variant={'contained'}
                style={buttonStyle}
                onClick={() => setIsCreateAccountOpen(false)}>
                <div >Back to Log in</div>
            </Button>
            <ErrorModal errorMessage={errorMessage} onClose={() => setErrorMessage('')} open={!!errorMessage}/>
        </div>
    )
}