import React from "react";
import {Snackbar} from "@mui/material";

export function SuccessModal(props) {
    const {successMessage, onClose, open} = props;

    return (
        <div>
            <Snackbar open={open} style={{
                backgroundColor: "rgba(34,255,0,0.8)",
                padding: 10,
                borderRadius: 5,
                margin: 10,
                alignItems: "center",
                display: 'flex', justifyContent: 'center', marginTop: '20px'
            }} autoHideDuration={3000} onClose={onClose}>
                <div style={{
                    color: "#ffffff",
                    fontSize: 16,
                    fontWeight: "bold",
                }}>{successMessage}</div>
            </Snackbar>
        </div>
    );
}