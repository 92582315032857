import React, {useState} from "react";
import {getFunctions, httpsCallable} from "firebase/functions";
import {LoadingIcon} from "./LoadingIcon";
import {Button, IconButton, InputAdornment, TextField} from "@mui/material";
import {buttonStyle, buttonStyleSettings} from "../util/styles";
import {FIREBASE_AUTH} from "../firebaseConfig";
import {Close} from "@mui/icons-material";
import { motion } from "framer-motion"
import {SupportedLanguages} from "../types/enums";
import {CheckedIcon} from "./CheckIcon";


export const ChatContainer = (props) => {

    const {language, voiceSpeed} = props

    const [message, setMessage] = useState('')
    const [response, setResponse] = useState('')
    const functions = getFunctions();
    const englishConversation = httpsCallable(functions,
        'englishconversationweb');
    const arabicConversation = httpsCallable(functions,
        'arabicconversationweb');
    const spanishConversation = httpsCallable(functions,
        'spanishconversationweb');
    const frenchConversation = httpsCallable(functions,
        'frenchconversationweb');
    const russianConversation = httpsCallable(functions,
        'russianconversationweb');
    const createCard = httpsCallable(functions,
        'createcard');
    const getTTS = httpsCallable(functions, 'gettexttospeechurlweb');
    const [soundUrl, setSoundUrl] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [translationId, setTranslationId] = useState('')


    const playSound = async (soundUrl) => {
        const audio = new Audio(soundUrl);
        await audio.play();
    };


    return (<motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                duration: 2,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
            }}
            style={{ textAlign: 'center', marginBottom: '10px', display: "flex",
                flexDirection: 'column', }}
        >
            <div style={{
                flexDirection: 'row',
                display: 'flex',
                marginBottom: '20px',
                marginTop: '20px'
            }}>
                <div style={{
                    marginLeft: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#FFFFFF'
                }}>
                    {`Voice Speed: ${Math.ceil(voiceSpeed * 100)}%`}
                </div>
                <div style={{
                    marginLeft: 'auto',
                    marginRight: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '30%',
                    color: '#FFFFFF'
                }}>
                    {language}
                </div>
            </div>
            <div style={{display: "flex", justifyContent: 'center'}}>
                <TextField
                    style={{
                        width: '80%',
                        marginTop: '10px',
                        border: '2px solid #FFFFFF',
                        color: '#FFFFFF'
                    }}
                    onChange={(event) => setMessage(event.target.value)}
                    autoCapitalize={'none'}
                    value={message}
                    placeholder={'Type or use voice-to-text.'}
                    InputProps={{
                        style: {color: '#FFFFFF'},
                        endAdornment: (
                            <InputAdornment position="end">
                                {message && (
                                    <IconButton onClick={() => setMessage('')}>
                                        <Close style={{color: '#FFFFFF'}}/>
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <Button
                variant={'contained'}
                style={buttonStyle}
                onClick={() => {
                    setResponse('');
                    setIsLoading(true);
                    (SupportedLanguages.ENGLISH === language)
                    && englishConversation({
                        text: message,
                        userId: FIREBASE_AUTH.currentUser.uid,
                        time: Date().toLocaleString(),
                        voiceSpeed: voiceSpeed
                    })
                    .then((result) => {
                        const data = result.data;
                        setResponse(data.text)
                        setTranslationId(data.id)
                        getTTS({text: `${data.id}.mp3`})
                        .then((response) => {
                            setSoundUrl(response.data[0])
                        })
                        .catch((e) => console.log(e))
                    }).catch((e) => setResponse(e.message))
                    .finally(() => setIsLoading(false));
                    (SupportedLanguages.ARABIC === language)
                    && arabicConversation({
                        text: message,
                        userId: FIREBASE_AUTH.currentUser.uid,
                        time: Date().toLocaleString(),
                        voiceSpeed: voiceSpeed
                    })
                    .then((result) => {
                        console.log(result)
                        const data = result.data;
                        setResponse(data.text)
                        setTranslationId(data.id)
                        getTTS({text: `${data.id}.mp3`})
                        .then((response) => {
                            setSoundUrl(response.data[0])
                        })
                        .catch((e) => console.log(e))
                    }).catch((e) => setResponse(e.message))
                    .finally(() => setIsLoading(false));
                    (SupportedLanguages.SPANISH === language)
                    && spanishConversation({
                        text: message,
                        userId: FIREBASE_AUTH.currentUser.uid,
                        time: Date().toLocaleString(),
                        voiceSpeed: voiceSpeed
                    })
                    .then((result) => {
                        console.log(result)
                        const data = result.data;
                        setResponse(data.text)
                        setTranslationId(data.id)
                        getTTS({text: `${data.id}.mp3`})
                        .then((response) => {
                            console.log(response.data[0])
                            setSoundUrl(response.data[0])
                        })
                        .catch((e) => console.log(e))
                    }).catch((e) => setResponse(e.message))
                    .finally(() => setIsLoading(false));
                    (SupportedLanguages.FRENCH === language)
                    && frenchConversation({
                        text: message,
                        userId: FIREBASE_AUTH.currentUser.uid,
                        time: Date().toLocaleString(),
                        voiceSpeed: voiceSpeed
                    })
                    .then((result) => {
                        console.log(result)
                        const data = result.data;
                        setTranslationId(data.id)
                        setResponse(data.text)
                        getTTS({text: `${data.id}.mp3`})
                        .then((response) => {
                            console.log(response.data[0])
                            setSoundUrl(response.data[0])
                        })
                        .catch((e) => console.log(e))
                    }).catch((e) => setResponse(e.message))
                    .finally(() => setIsLoading(false));
                    (SupportedLanguages.RUSSIAN === language)
                    && russianConversation({
                        text: message,
                        userId: FIREBASE_AUTH.currentUser.uid,
                        time: Date().toLocaleString(),
                        voiceSpeed: voiceSpeed
                    })
                    .then((result) => {
                        console.log(result)
                        const data = result.data;
                        setResponse(data.text)
                        setTranslationId(data.id)
                        getTTS({text: `${data.id}.mp3`})
                        .then((response) => {
                            console.log(response.data[0])
                            setSoundUrl(response.data[0])
                        })
                        .catch((e) => console.log(e))
                    }).catch((e) => setResponse(e.message))
                    .finally(() => setIsLoading(false));
                }}>
                <div>Translate</div>
            </Button>
            <Button
                variant={'contained'}
                style={buttonStyle}
                onClick={async () => {
                    await playSound(soundUrl)
                }}>
                <div>Hear Response</div>
            </Button>
            <div style={{display: 'flex', justifyContent: 'center', margin: '10px'}}>
            <div style={{height: '90px', width: '90%', backgroundColor:'#2c2c2c', overflow: 'scroll'}}>
                <div style={{textAlign: 'left', fontSize: 20, color: '#ffffff', margin: '5px'}}>
                    {response !== '' && <motion.div
                        initial={{opacity: 0, scale: 0.5}}
                        animate={{opacity: 1, scale: 1}}
                        transition={{
                            duration: 1.5,
                            delay: 0,
                            ease: [0, 0.71, 0.2, 1.01],
                        }}

                    >
                        {response}
                    </motion.div>}
                </div>
            </div>
            </div>
            <div style={{display: 'flex'}}>
            <Button
                variant={'contained'}
                style={buttonStyleSettings}
                disabled={translationId === '' || message === ''}
                onClick={ () => {
                    setIsLoading(true)
                    setResponse('')
                    createCard({
                        translation: translationId,
                        userId: FIREBASE_AUTH.currentUser.uid,
                    })
                    .then(() => {
                        {
                            setIsLoading(false)
                            setTranslationId('')
                            setMessage('')
                            setResponse('Card created successfully.')
                        }
                    })
                    .catch((e) => {
                        setIsLoading(false)
                        setTranslationId('')
                        setMessage('')
                        setResponse('An error occurred while creating the deck.')
                        console.log(e)
                    })

                }}>
                <div>Create Card</div>
            </Button>
                <LoadingIcon isLoading={isLoading}/>
                <CheckedIcon isLoading={isLoading}/>
            </div>
        </motion.div>
    );
}