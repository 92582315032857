import React from 'react';
import CheckIcon from '@mui/icons-material/Check';

export const CheckedIcon = (props) => {
    const {isLoading} = props

    return <>
        {!isLoading &&
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    margin: 'auto',
                    color: '#FFFFFF'
                }}
            >
                <CheckIcon/>
            </div>
        }
    </>
}