import React from "react";
import {Snackbar} from "@mui/material";
export const commonMessageFromFirebaseErrorCode = (errorCode, setErrorMessage) => {
    switch (errorCode) {
        case "auth/invalid-email":
            setErrorMessage("Email entered was invalid. Please try again.");
            break;
        case "auth/missing-email":
            setErrorMessage("Please enter an email address.");
            break;
        case "auth/email-already-in-use":
            setErrorMessage("Email already is use. Please try to log in.");
            break;
        case "auth/invalid-login-credentials":
            setErrorMessage("Invalid email and password combination.");
            break;
        case "auth/missing-password":
            setErrorMessage("Please enter your password.");
            break;
        default:
            setErrorMessage(errorCode);
            break;
    }
}
export function ErrorModal(props) {
    const {errorMessage, open, onClose} = props;

    return (
        <div >
            <Snackbar open={open} onClose={onClose} style={{
                backgroundColor: "rgba(255,0,8,0.8)",
                padding: 10,
                borderRadius: 5,
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }} autoHideDuration={3000}>
                <div style={{
                    color: "#ffffff",
                    fontSize: 16,
                    fontWeight: "bold",
                }}>{errorMessage}</div>
            </Snackbar>
        </div>
    );
}

