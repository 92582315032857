import React from 'react';
import { motion } from "framer-motion"

const Title = () => {
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                duration: 1,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
            }}
            style={{ textAlign: 'center', marginBottom: '10px' }}
        >
            <h1 style={{display: "flex", justifyContent: 'center', color: '#FFFFFF'}} >Fluency Rise</h1>
            <h3 style={{display: "flex", justifyContent: 'center', color: '#FFFFFF'}} >Flashcard Generation With AI</h3>
        </motion.div>
    );
};

export default Title;