import React, {useEffect, useState} from 'react';
import { motion } from "framer-motion"
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
export const LoadingIcon = (props) => {
    const {isLoading} = props
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        const rotationInterval = setInterval(() => {
            setRotation((prevRotation) => prevRotation - 1);
        }, 10); // Adjust the interval duration to control the rotation speed

        return () => {
            clearInterval(rotationInterval);
        };
    }, []);

    return <>
        {isLoading &&
            <motion.div
                style={{
                    rotate: rotation, // Apply the rotation using the 'rotate' property
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    margin: 'auto',
                    color: '#FFFFFF'
                }}
            >
                <HourglassBottomIcon/>
            </motion.div>
}
</>
}