import React from "react";

export const SmallCard = (props) => {
    const {setCards, id, cards, bigText, smallText, language} = props

    return (
        <div
            style={{
                minWidth: '100px',
                backgroundColor: cards.includes(id)? '#10ffcd': '#d9d9d9',
                height: '100px',
                margin: '5px',
        }}
            onClick={() => {
                cards.includes(id)?
                    setCards(prevList => prevList.filter((element) => element !== id))
                    :setCards(prevCards => [...prevCards, id])
            }}>
            <div style={{fontSize: 'xx-small', display: 'flex', flexDirection: 'column', textAlign: 'left', margin: '2px', overflow: 'scroll'}}>
                <div>{language}</div>
                <div style={{fontWeight: 'bold'}}>English</div>
                <div>{bigText}</div>
                <div style={{fontWeight: 'bold'}}>Translation</div>
                <div>{smallText}</div>
            </div>
        </div>
    )
}