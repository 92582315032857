import React, {useEffect, useState} from 'react';
import { motion } from 'framer-motion';
import {Button, Slider, Typography} from "@mui/material";
import {signOut} from "firebase/auth";
import {FIREBASE_AUTH} from "../../firebaseConfig";
import {buttonStyle} from "../../util/styles";
import {SimpleCustomOptions} from "../SimpleCustomOptions";
import {ModeOptions} from "../ModeOptions";
import {DeckOptions} from "../DeckOptions";
import {AvailableCards} from "../AvailableCards";
import {getFunctions, httpsCallable} from "firebase/functions";

export const SettingsNavigation = (props) => {
    const {setLanguage, setVoiceSpeed, voiceSpeed, language, setMode, mode, setDeck} = props
    const [refreshDecks, setRefreshDecks] = useState(false)
    const [userDecks, setUserDecks] = useState([])
    const functions = getFunctions();

    const retrieveDecksByUserId = httpsCallable(functions,
        'retrievedecksbyuserid');

    useEffect(() => {
        retrieveDecksByUserId({
            userId: FIREBASE_AUTH.currentUser.uid,
        })
        .then((response) => {
            response.data && setUserDecks(response.data)
            if(response.data && response.data.length > 0){
                response.data[0].name && setDeck(response.data[0].name)
            }
        })
        .catch((e) => {
            console.log(e)
        })
    }, []);

    useEffect(() => {
        refreshDecks && retrieveDecksByUserId({
            userId: FIREBASE_AUTH.currentUser.uid,
        })
        .then((response) => {
            response.data && setUserDecks(response.data)
        })
        .catch((e) => {
            console.log(e)
        })
        .finally(() => setRefreshDecks(false))

    }, [refreshDecks]);

    return (
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 1,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01]
                }}
                style={{ textAlign: 'center', marginBottom: '10px', zIndex: 2110 }}
            >
                <div style={{
                    flexDirection: 'row',
                        display: 'flex',
                    alignItems: 'center',
                    gap: '30px',
                    justifyContent: 'center',
                    marginTop: '20px',
                }}>
                <ModeOptions setMode={setMode} mode={mode}/>
                {mode === 'Create Cards' && <SimpleCustomOptions setLanguage={setLanguage} langauge={language}/>}
                {mode === 'Deck' && <DeckOptions userDecks={userDecks.map(deck => deck.name)} setDeck={setDeck}/>}
                </div>
                {mode === 'Create Cards' && <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography style={{marginTop: '10px', color: '#FFFFFF'}} id="non-linear-slider" gutterBottom>
                        {`Voice Speed ${Math.ceil(voiceSpeed * 100)}%`}
                    </Typography>
                    <Slider
                        value={voiceSpeed}
                        min={.26}
                        step={0.01}
                        max={1}
                        style={{margin: 'auto', color: '#FFFFFF', width: '80%'}}
                        onChange={(event) => {
                            setVoiceSpeed(event.target.value)
                        }}
                        aria-labelledby="voice speed"
                        valueLabelDisplay="off"
                    />
                </div>}
                {mode === 'Deck' && <AvailableCards setRefreshDecks={setRefreshDecks}/>}
                <Button
                    variant={'contained'}
                    style={buttonStyle}
                    onClick={
                        () => signOut(FIREBASE_AUTH).then(() => {
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                >
                    Log Out
                </Button>
            </motion.div>

    );
};
