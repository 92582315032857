import React, {useEffect, useState} from "react";
import { onAuthStateChanged } from "firebase/auth";
import {FIREBASE_AUTH} from "./firebaseConfig";
import {MoonParallaxTheme} from "./components/MoonParallaxTheme";
import {Login} from "./components/Login";



export default function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        onAuthStateChanged(FIREBASE_AUTH, (user) => {
            if (user) {
                user.reload().then(() => {
                    if(!user.emailVerified){
                        setUser(null)
                    }else{
                        setUser(user); //User is signed in
                    }
                })
            } else {
                setUser(null); // User is signed out
            }
        });
    }, )

    return (
        < >
            {user ? (
                <MoonParallaxTheme/>
            ) : (
                    <Login/>
            )}
        </>
    );
}

