import React, {useState} from "react";
import {CreateAccount} from "./CreateAccount";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import {FIREBASE_AUTH} from "../firebaseConfig";
import {commonMessageFromFirebaseErrorCode, ErrorModal} from "./ErrorModal";
import {SuccessModal} from "./SuccessModal";
import {Button, TextField} from "@mui/material";
import {buttonStyle} from "../util/styles";
import Title from "./Title";

export const Login = () => {
    const [isCreateAccountOpen, setIsCreateAccountOpen] = useState(false)
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    return (
        <>
            {isCreateAccountOpen ? <CreateAccount setIsCreateAccountOpen={setIsCreateAccountOpen} setSuccessMessage={setSuccessMessage}/>
                :
                <div style={{display: "flex",  flexDirection: 'column'}} >
                    <Title/>
                    <div style={{display: "flex", justifyContent: 'center'}} >
                        <TextField
                            InputProps={{
                                style: {
                                    color: '#FFFFFF',
                                },
                            }}
                            style={{width: '80%', border: '2px solid #FFFFFF', color: '#FFFFFF'}}
                            onChange={(event) => setEmail(event.target.value)}
                            autoCapitalize={'none'}
                            value={email}
                            placeholder={'Email'}/>
                    </div>
                    <div style={{display: "flex", justifyContent: 'center'}} >
                        <TextField
                            InputProps={{
                                style: {
                                    color: '#FFFFFF',
                                },
                            }}
                            style={{width: '80%', marginTop: '10px', border: '2px solid #FFFFFF', color: '#000000'}}
                            onChange={(event) => setPassword(event.target.value)}
                            autoCapitalize={'none'}
                            value={password}
                            type={'password'}
                            placeholder={'Password'}/>
                    </div>
                    <Button
                        variant={'contained'}
                        style={buttonStyle}
                        onClick={() =>{
                            signInWithEmailAndPassword(FIREBASE_AUTH, email, password)
                            .then((userCredential) => {
                                // Signed in
                                const user = userCredential.user;
                                if(!user.emailVerified){
                                    setSuccessMessage('Please verify email.')
                                }
                            })
                            .catch((error) => {
                                const errorCode = error.code;
                                commonMessageFromFirebaseErrorCode(errorCode, setErrorMessage)
                            });

                        }}>
                        <div >Login</div>
                    </Button>
                    <Button
                        variant={'contained'}
                        style={buttonStyle}
                        onClick={() => setIsCreateAccountOpen(true)}>
                        Create Account
                    </Button>
                    <Button
                        variant={'contained'}
                        style={buttonStyle}
                        onClick={() => {
                            if(email){
                                sendPasswordResetEmail(FIREBASE_AUTH, email)
                                .then(() => {
                                    setSuccessMessage(`Password reset email sent to ${email}`)
                                    console.log('success')
                                })
                                .catch((error) => {
                                    const errorMessage = error.message;
                                    console.log('error')
                                    setErrorMessage(errorMessage)
                                });
                            }else {
                                setErrorMessage('Type an email to receive password reset link in email.')
                                console.log('error')
                            }
                        }}>
                        <div >Forgot Password?</div>
                    </Button>
                </div>
            }
            <SuccessModal successMessage={successMessage} onClose={() => setSuccessMessage('')} open={!!successMessage}/>
            <ErrorModal errorMessage={errorMessage} onClose={() => setErrorMessage('')} open={!!errorMessage}/>

        </>)
}