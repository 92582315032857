import { ParallaxLayer, Parallax } from "@react-spring/parallax";
import {React, useEffect, useRef, useState} from "react";
import SettingsIcon from '@mui/icons-material/Settings';

import {ChatContainer} from "./ChatContainer";
import {SettingsNavigation} from "./settings/SettingsNavigation";
import {SupportedLanguages} from "../types/enums";
import {ToModeTab} from "./ToModeTab";
import {getFunctions, httpsCallable} from "firebase/functions";
import {FIREBASE_AUTH} from "../firebaseConfig";
import {FlashCards} from "./FlashCards";

export const MoonParallaxTheme = () => {
    const [language, setLanguage] = useState(SupportedLanguages.FRENCH)
    const [voiceSpeed, setVoiceSpeed] = useState(1)
    const parallax = useRef();
    const [mode, setMode] = useState('Create Cards')
    const [deck, setDeck] = useState('')
    const [displayedCards, setDisplayedCards] = useState([])
    const functions = getFunctions();
    const retrieveCardsByUserIdandDeckName = httpsCallable(functions,
        'retrievecardsbyuseridanddeckname');
    const iconStyle = {
        border: 'none',
        cursor: 'pointer',
        color: '#000000',
        margin: 'auto',
        display: 'flex',
        fontSize: '65px',
        marginTop: '8px',
    }

    useEffect(() => {
        parallax.current.scrollTo(1)
    }, []);

    useEffect(() => {
        deck && retrieveCardsByUserIdandDeckName({
            userId: FIREBASE_AUTH.currentUser.uid,
            deckName: deck
        }).then((response) => {
            response.data && setDisplayedCards(response.data.map(deck => deck.cards))
        })
    }, [deck]);


    return (
        <div>
            <Parallax ref={parallax} pages={2} style={{overflow: 'hidden'}}>
                <ParallaxLayer
                    offset={0}
                    style={{ backgroundColor: '#000000', zIndex: 998 }}
                >
                </ParallaxLayer>
                <ParallaxLayer offset={0} style={{ zIndex: 2000 }}>
                    {mode === 'Create Cards' && <ChatContainer language={language} voiceSpeed={voiceSpeed}/>}
                    {mode === 'Deck' && displayedCards && displayedCards.length > 0 && <FlashCards deckName={deck} cards={displayedCards[0]}/>}
                </ParallaxLayer>
                <ParallaxLayer
                    offset={.85}
                    onClick={() => {
                    parallax.current.scrollTo(1)}}
                    style={{ cursor: 'pointer', zIndex: 2001, backgroundColor: "#FFFFFF" }} >
                <SettingsIcon style={iconStyle} />
                </ParallaxLayer>
                <ParallaxLayer offset={1.0} style={{ cursor: 'pointer', zIndex: 2001, backgroundColor: "#FFFFFF" }}  onClick={() => parallax.current.scrollTo(0)}>
                    <ToModeTab mode={mode}/>
                </ParallaxLayer>
                <ParallaxLayer style={{ zIndex: 2002, backgroundColor: '#000000' }} offset={1.09}>
                    <SettingsNavigation setDeck={setDeck} mode={mode} setMode={setMode} language={language} setLanguage={setLanguage} setVoiceSpeed={setVoiceSpeed} voiceSpeed={voiceSpeed}/>
                </ParallaxLayer>
            </Parallax>
        </div>
    );
};
