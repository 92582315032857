import React, {useEffect, useState} from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LoopIcon from '@mui/icons-material/Loop';
import HearingIcon from '@mui/icons-material/Hearing';
import {getFunctions, httpsCallable} from "firebase/functions";
import { motion } from "framer-motion"


export const FlashCards = (props) => {
    const {cards, deckName} = props
    const [currentCard, setCurrentCard] = useState(0)
    const [frontFacing, setFrontFacing] = useState(true)
    const [totalCards, setTotalCards] = useState(0)
    const functions = getFunctions();
    const [cardInfo, setCardInfo] = useState()

    const retrieveCardById = httpsCallable(functions,
        'retrievecardbyid');

    const playSound = async (soundUrl) => {
        const audio = new Audio(soundUrl);
        await audio.play();
    };

    useEffect(() => {
        cards && setTotalCards(cards.length)
        setCurrentCard(0)
    }, [cards])

    useEffect(() => {
        cards[currentCard] !== '' && retrieveCardById({text: cards[currentCard]})
            .then((response) => {
                setCardInfo(response.data)
            })
        .catch((e) => {
            console.log(e)
            setCardInfo({
                translatedText: 'Unable to find card.',
                originalText: 'Unable to find card'
            })
        })
    }, [currentCard]);

    return (
        <div>
            <div style={{marginTop: '5px', color: '#ffffff', display: 'flex', justifyContent: 'center'}}>{deckName} {currentCard+1}/{totalCards}</div>
            <div style={{display: 'flex', justifyContent:'center'}}>
                <div style={{minHeight: '350px', maxHeight: '360px',borderRadius: '20px', minWidth: '80%', maxWidth: '80%', backgroundColor: '#ffffff', color: '#000000', marginTop: '5px', marginLeft: '30px', marginRight: '30px'}}>
                    <div style={{margin: '10px'}}>
                        <div>
                            <div style={{margin: '10px', fontWeight: 'bold', fontSize: 'xx-large'}}>
                                {frontFacing? 'English': 'Translation'}
                            </div>
                            <div style={{minHeight: '225px', minWidth: '90%', maxWidth: '90%', backgroundColor: '#d6d6d6', margin: '10px', overflow: 'scroll', fontSize: 'xx-large'}}>
                                    {cardInfo && frontFacing && <motion.div
                                        initial={{ opacity: 0, scale: 0.5 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{
                                            duration: 1.5,
                                            delay: 0,
                                            ease: [0, 0.71, 0.2, 1.01],
                                        }}

                                    >{(cardInfo.originalText)}</motion.div>}
                                {cardInfo && !frontFacing && <motion.div
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                        duration: 1.5,
                                        delay: 0,
                                        ease: [0, 0.71, 0.2, 1.01],
                                    }}

                                >{(cardInfo.translatedText)}</motion.div>}
                            </div>
                            {!frontFacing &&  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', color: '#000000', marginTop: '2%'}}>
                                <HearingIcon style={{fontSize: '40px'}} onClick={async () => {
                                    cardInfo && await playSound(cardInfo.url)
                                }}/>
                            </div>}
                        </div>
                    </div>
                </div>

            </div>
            <div style={{display: 'flex', marginTop: '5px', flexDirection: 'row', gap: '10%', justifyContent: 'center', alignItems: 'center', fontSize: 'xxx-large', color: '#ffffff'}}>
                <ArrowBackIosIcon style={{fontSize: '40px', margin: '10px'}} onClick={() => {
                    setCardInfo(undefined)
                    setFrontFacing(true)
                    setCurrentCard((currentCard - 1) % totalCards)
                }}/>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: 'xxx-large', color: '#ffffff', margin: 'auto'}}>
                    <LoopIcon style={{fontSize: '60px'}} onClick={() => {setFrontFacing(!frontFacing)}}/>
                </div>
                <ArrowForwardIosIcon style={{fontSize: '40px', margin: '10px'}} onClick={() => {
                    setCardInfo(undefined)
                    setFrontFacing(true)
                    setCurrentCard((currentCard + 1) % totalCards)
                }}/>
            </div>
        </div>
    )
}