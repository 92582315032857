import React from "react";
import KeyboardDoubleArrowUpIcon
    from "@mui/icons-material/KeyboardDoubleArrowUp";

export const ToModeTab = (props) => {
    const {mode} = props
    const iconStyle = {
        border: 'none',
        cursor: 'pointer',
        color: '#000000',
        fontSize: '50px',
    }

    return (
        <div style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
        }}>
            <KeyboardDoubleArrowUpIcon style={iconStyle}/>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                color: '#000000'
            }}>{mode}</div>
            <KeyboardDoubleArrowUpIcon style={iconStyle}/>
        </div>
    )
}