import { initializeApp } from "firebase/app";
import { GoogleAuthProvider } from "firebase/auth";
import { getAuth } from 'firebase/auth';

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCVhW96SNnmOhMx0bLCBi2dOlH5o0viYv0",
    authDomain: "ssspeak-6a1bb.firebaseapp.com",
    projectId: "ssspeak-6a1bb",
    storageBucket: "ssspeak-6a1bb.appspot.com",
    messagingSenderId: "666510370586",
    appId: "1:666510370586:web:1ae83a99cc370f9e906ea3",
    measurementId: "G-2C8RCRJNZZ"
};

export const FIREBASE_APP = initializeApp(firebaseConfig)
export const FIREBASE_AUTH = getAuth()
export const GOOGLE_PROVIDER = new GoogleAuthProvider()
