import React, {useEffect, useState} from 'react'
import {SmallCard} from "./SmallCard";
import {Button, IconButton, InputAdornment, TextField} from "@mui/material";
import {buttonStyleSettings} from "../util/styles";
import {LoadingIcon} from "./LoadingIcon";
import {CheckedIcon} from "./CheckIcon";
import {Close} from "@mui/icons-material";
import {getFunctions, httpsCallable} from "firebase/functions";
import {FIREBASE_AUTH} from "../firebaseConfig";


export const AvailableCards = (props) => {
    const {setRefreshDecks} = props
    const [cards, setCards] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [deckName, setDeckName] = useState('')
    const functions = getFunctions();
    const [allCards, setAllCards] = useState([])

    const retrieveCardsByUserId = httpsCallable(functions,
        'retrievecardsbyuserid');
    const createDeck = httpsCallable(functions,
        'createdeck');


    useEffect(() => {
        setIsLoading(true)
        retrieveCardsByUserId({
            userId: FIREBASE_AUTH.currentUser.uid,
        })
        .then((response) => {
            setAllCards(response.data)
            setIsLoading(false)
        })
        .catch((e) => {
            setIsLoading(false)
            console.log(e)
        })
    }, [])



    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center',marginTop:'10px', marginRight: '10px', marginLeft: '10px', alignItems: 'center'}}>
                <div style={{
                    display: 'flex',
                    flexDirection: "row",
                    height: '115px',
                    width: '90%',
                    backgroundColor:'#2c2c2c',
                    overflow: 'scroll'}}
                >
                    {allCards.map((card) =>
                        <SmallCard language={card.language} bigText={card.english} smallText={card.translation} setCards={setCards} cards={cards} id={card.id}/>
                    )}
                       </div>
            </div>
            <div style={{display: "flex", justifyContent: 'center', marginBottom: '5px'}}>
                <TextField
                    style={{
                        width: '80%',
                        marginTop: '10px',
                        border: '2px solid #FFFFFF',
                        color: '#FFFFFF'
                    }}
                    onChange={(event) => setDeckName(event.target.value)}
                    autoCapitalize={'none'}
                    value={deckName}
                    placeholder={'Type a name for the deck.'}
                    InputProps={{
                        style: {color: '#FFFFFF'},
                        endAdornment: (
                            <InputAdornment position="end">
                                {deckName && (
                                    <IconButton onClick={() => setDeckName('')}>
                                        <Close style={{color: '#FFFFFF'}}/>
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div style={{display: 'flex', marginTop: '10px', marginBottom: '40px'}}>
                <Button
                    disabled={deckName === '' || cards.length < 1}
                    variant={'contained'}
                    style={buttonStyleSettings}
                    onClick={ () => {
                        setIsLoading(true)
                        createDeck({
                            deckName: deckName,
                            userId: FIREBASE_AUTH.currentUser.uid,
                            cards: cards
                        })
                            .then((response) => {
                                setIsLoading(false)
                                setRefreshDecks(true)
                                setCards([])
                            })
                            .catch((e) => {
                                setIsLoading(false)
                                setCards([])
                                console.log(e)
                            })
                    }}>
                    <div>Create Deck</div>
                </Button>
                <LoadingIcon isLoading={isLoading}/>
                <CheckedIcon isLoading={isLoading}/>
            </div>
        </>
    )

}